export class Tickets {
    private _by_games: number;
    private _by_assoc_don: number;
    private _by_appli_don: number;

	constructor(by_games: number, by_assoc_don: number, 
        by_appli_don: number) {
		this._by_games = by_games;
		this._by_assoc_don = by_assoc_don;
		this._by_appli_don = by_appli_don;
	}

    /**
     * Getter by_games
     * @return {number}
     */
	public get by_games(): number {
		return this._by_games;
	}

    /**
     * Getter by_assoc_don
     * @return {number}
     */
	public get by_assoc_don(): number {
		return this._by_assoc_don;
	}

    /**
     * Getter by_appli_don
     * @return {number}
     */
	public get by_appli_don(): number {
		return this._by_appli_don;
	}

    /**
     * Setter by_games
     * @param {number} value
     */
	public set by_games(value: number) {
		this._by_games = value;
	}

    /**
     * Setter by_assoc_don
     * @param {number} value
     */
	public set by_assoc_don(value: number) {
		this._by_assoc_don = value;
	}

    /**
     * Setter by_appli_don
     * @param {number} value
     */
	public set by_appli_don(value: number) {
		this._by_appli_don = value;
	}

}
