import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { DatePipe } from '@angular/common';

import firebase from 'firebase/compat/app';
import 'firebase/compat/database';
import 'firebase/compat/storage';
import DataSnapshot = firebase.database.DataSnapshot;

@Injectable({
  providedIn: 'root'
})
export class AppService {

  isOn$ = new Subject<boolean>();
  isOn = false;

  imgHome$ = new Subject<string[]>();
  imgHome = ["", "", ""];

  imgGiftFocus$ = new Subject<string>();
  imgGiftFocus = "";
  
  imgTutoConcept$ = new Subject<string[]>();
  imgTutoConcept = ["", ""];

  imgTutoGetTickets$ = new Subject<string[]>();
  imgTutoGetTickets = ["", "", ""];

  imgAssocDon$ = new Subject<string[]>();
  imgAssocDon = ["", ""];
  
  assoc_txt$ = new Subject<string[]>();
  assoc_txt = ["", ""];

  gift_txt$ = new Subject<string[]>();
  gift_txt = ["", "", "", "", "", ""];

  collect_txt$ = new Subject<string[]>();
  collect_txt = ["", "", ""];

  constructor(
    private datepipe: DatePipe
  ) {
    this.isLive();
    this.getImagesStorage();
    this.getTextDatabase();
  }

  async emitIsOn() {
    return this.sleep(500).then(v => {
      this.isOn$.next(this.isOn);
    });
  }

  emitInstantIsOn() {
    this.isOn$.next(this.isOn);
  }

  emitImagesTutoConcept() {
    this.imgTutoConcept$.next(this.imgTutoConcept);
  }

  emitImageGiftFocus() {
    this.imgGiftFocus$.next(this.imgGiftFocus);
  }

  emitImagesAssocDon() {
    this.imgAssocDon$.next(this.imgAssocDon);
  }

  async emitImagesHome() {
    return this.sleep(1000).then(v => {
      this.imgHome$.next(this.imgHome);
      this.imgTutoGetTickets$.next(this.imgTutoGetTickets);
      this.emitImagesTutoConcept();
    });
  }

  emitTextGiftFocus() {
    this.gift_txt$.next(this.gift_txt);
  }

  emitTextCollect() {
    this.collect_txt$.next(this.collect_txt);
  }

  emitTextAssocDon() {
    this.assoc_txt$.next(this.assoc_txt);
  }

  async emitTextHome() {
    return this.sleep(1000).then(v => {
      this.assoc_txt$.next(this.assoc_txt);
    });
  }

  isLive() {
    return new Promise((resolve, reject) => {
      firebase.database().ref("Live")
      .child(this.datepipe.transform(new Date(), 'yyyy'))
      .child(this.datepipe.transform(new Date(), 'MM'))
      .once("value").then(
        (data: any) => {
          if (data.val() != null) {
            this.isOn = true;
            this.emitIsOn();
          } else {
            this.isOn = false;
            this.emitIsOn();
          }
          resolve(data);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }
  
  getImagesStorage() {
    firebase.storage().ref("/Home/gift.png").getDownloadURL().then(urlImg => {
      this.imgHome[0] = urlImg;
      firebase.storage().ref("/Home/gift_tuto.png").getDownloadURL().then(urlImg => {
        this.imgTutoGetTickets[0] = urlImg;
        firebase.storage().ref("/Assoc/assoc_logo.png").getDownloadURL().then(urlImg => {
          this.imgHome[1] = urlImg;
          firebase.storage().ref("/Assoc/assoc_bg.jpg").getDownloadURL().then(urlImg => {
            this.imgHome[2] = urlImg;
            this.imgTutoGetTickets[2] = urlImg;
            this.imgAssocDon[1] = urlImg;
            firebase.storage().ref("/Gift/gift_animated.gif").getDownloadURL().then(urlImg => {
              this.imgGiftFocus = urlImg;
              firebase.storage().ref("/Tuto/get_tickets.png").getDownloadURL().then(urlImg => {
                this.imgTutoConcept[0] = urlImg;
                firebase.storage().ref("/Tuto/for_win.png").getDownloadURL().then(urlImg => {
                  this.imgTutoConcept[1] = urlImg;
                  firebase.storage().ref("/Tuto/tickets_don_assoc.png").getDownloadURL().then(urlImg => {
                    this.imgTutoGetTickets[1] = urlImg;
                    firebase.storage().ref("/Donation/don_assoc_tickets.png").getDownloadURL().then(async urlImg => {
                      this.imgAssocDon[0] = urlImg;
                      await this.emitImagesHome();
                    });
                  });
                });
              });
            });
          });
        });
      });
    });
  }

  getTextDatabase() {
    firebase.database().ref("Text").once("value").then(
      async (textList: DataSnapshot) => {
        this.assoc_txt = [textList.val().AssocDef.Def1, 
        textList.val().AssocDef.Def2];
        this.gift_txt = [textList.val().GiftDef.Def1, 
        textList.val().GiftDef.Def2, 
        textList.val().GiftDef.Def3, 
        textList.val().GiftDef.Def4, 
        textList.val().GiftDef.Def5, 
        textList.val().GiftDef.Def6];
        await this.emitTextHome();
      }
    );
    firebase.database().ref("Collect").once("value").then(
      (textList: DataSnapshot) => {
        this.collect_txt = [textList.val().Association, 
        textList.val().LoveInYou, 
        textList.val().Website];
      }
    );
  }

  sleep (ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms))
  }

}
