import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { UserService } from '../user/user.service';
import { TicketsService } from '../tickets/tickets.service';
import { DonationsService } from '../donations/donations.service';
import { Profile } from 'src/app/models/profile.model';

import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  isAuth$ = new Subject<boolean>();
  isAuth = false;
  
  profile$ = new Subject<Profile>();
  profile = new Profile("","");

  constructor(
    private userService: UserService,
    private ticketsService: TicketsService,
    private donationsService: DonationsService
  ) {
    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        this.setIsAuth(true);
        setTimeout(() => {
          this.getProfile();
        }, 1000);
      } else {
        this.setIsAuth(false);
        this.resetProfile();
      }
    });
  }

  emitIsAuth() {
    this.isAuth$.next(this.isAuth);
  }

  emitProfile() {
    this.profile$.next(this.profile);
  }

  setIsAuth(isAuth: boolean) {
    this.isAuth = isAuth;
    this.emitIsAuth();
  }

  getProfile() {
    this.profile = new Profile((firebase.auth().currentUser).displayName, 
    (firebase.auth().currentUser).photoURL);
    this.emitProfile();
  }

  resetProfile() {
    this.profile = new Profile("","");
    this.emitProfile();
  }

  getUserId() {
    return (firebase.auth().currentUser).uid;
  }

  signUpUser(email: string, password: string, username: string, number: number) {
    return new Promise((resolve, reject) => {
      firebase.auth().createUserWithEmailAndPassword(email, password).then(
        (userCredential) => {
          this.saveProfile(username, "https://example.com/username/profile.jpg");
          this.userService.saveUser(email.toLowerCase(), username, number);
          this.ticketsService.saveTickets();
          this.donationsService.saveDonations();
          resolve(userCredential);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }

  createDisplayName(username: string) {
    return username + "#" + Math.floor(Math.random() * 10000).toString().padStart(4, '0');
  }
  
  saveProfile(username: string, photoURL: string) {
    const displayName = this.createDisplayName(username);
    return new Promise((resolve, reject) => {
      (firebase.auth().currentUser).updateProfile({
        displayName: displayName,
        photoURL: photoURL
      }).then(
        (userCredential) => {
          this.profile = new Profile(displayName, photoURL);
          this.emitProfile();
          resolve(userCredential);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }
  
  editPhotoURL(photoURL: string) {
    return new Promise((resolve, reject) => {
      (firebase.auth().currentUser).updateProfile({
        photoURL: photoURL
      }).then(
        (userCredential) => {
          this.profile.photoURL = photoURL;
          this.emitProfile();
          resolve(userCredential);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }
  
  editDisplayName(username: string) {
    const displayName = this.createDisplayName(username);
    return new Promise((resolve, reject) => {
      (firebase.auth().currentUser).updateProfile({
        displayName: displayName
      }).then(
        (userCredential) => {
          this.profile.displayName = displayName;
          this.emitProfile();
          resolve(userCredential);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }

  signInUser(email: string, password: string) {
    return new Promise((resolve, reject) => {
      firebase.auth().signInWithEmailAndPassword(email, password).then(
        (userCredential) => {
          resolve(userCredential);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }

  signOut() {
    firebase.auth().signOut();
  }

}
