import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { News } from 'src/app/models/news.model';

import firebase from 'firebase/compat/app';
import 'firebase/compat/database';
import 'firebase/compat/storage';
import DataSnapshot = firebase.database.DataSnapshot;

@Injectable({
  providedIn: 'root'
})
export class NewsService {
  
  news$ = new Subject<News[]>();
  newsList: News[] = [];

  constructor() {
    this.getNewsDatabase();
  }

  async emitNews() {
    return this.sleep(500).then(v => {
      this.news$.next(this.newsList.slice());
    });
  }

  getNewsDatabase() {
    firebase.database().ref("News").once("value").then(
      async (newsList: DataSnapshot) => {
        for (const news of newsList.val()) {
          this.getNewsStorage(news);
          await this.emitNews();
        }
      }
    );
  }
  
  getNewsStorage(news: any) {
    const imageRef = news.image;
    const backgroundRef = news.background;
    firebase.storage().ref(imageRef).getDownloadURL().then(imageUrl => {
      firebase.storage().ref(backgroundRef).getDownloadURL().then(backgroundUrl => {
        this.newsList.push(new News(
          backgroundUrl,
          imageUrl,
          news.link
        ));
      });
    });
  }

  sleep (ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms))
  }

}
