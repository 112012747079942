export class Donations {
    private _for_assoc: number;
    private _for_appli: number;

	constructor(for_assoc: number, 
        for_appli: number) {
		this._for_assoc = for_assoc;
		this._for_appli = for_appli;
	}

    /**
     * Getter for_assoc
     * @return {number}
     */
	public get for_assoc(): number {
		return this._for_assoc;
	}

    /**
     * Getter for_appli
     * @return {number}
     */
	public get for_appli(): number {
		return this._for_appli;
	}

    /**
     * Setter for_assoc
     * @param {number} value
     */
	public set for_assoc(value: number) {
		this._for_assoc = value;
	}

    /**
     * Setter for_appli
     * @param {number} value
     */
	public set for_appli(value: number) {
		this._for_appli = value;
	}

}
