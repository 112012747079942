export class Profile {
    private _displayName: string;
    private _photoURL: string;

	constructor(displayName: string, photoURL: string) {
		this._displayName = displayName;
		this._photoURL = photoURL;
	}

    /**
     * Getter displayName
     * @return {string}
     */
	public get displayName(): string {
		return this._displayName;
	}

    /**
     * Getter photoURL
     * @return {string}
     */
	public get photoURL(): string {
		return this._photoURL;
	}

    /**
     * Setter displayName
     * @param {string} value
     */
	public set displayName(value: string) {
		this._displayName = value;
	}

    /**
     * Setter photoURL
     * @param {string} value
     */
	public set photoURL(value: string) {
		this._photoURL = value;
	}

}
