import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { IonicStorageModule } from '@ionic/storage-angular';
import { DatePipe } from '@angular/common';
import { SafePipe } from './pipes/safe-pipe';
import { AuthService } from './services/auth/auth.service';
import { UserService } from './services/user/user.service';
import { TicketsService } from './services/tickets/tickets.service';
import { NewsService } from './services/news/news.service';
import { LoaderService } from './services/loader/loader.service';
import { DonationsService } from './services/donations/donations.service';
import { AppService } from './services/app/app.service';

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule, 
    IonicModule.forRoot(), 
    AppRoutingModule, 
    IonicStorageModule.forRoot()
  ],
  providers: [
    DatePipe,
    SafePipe,
    AppService,
    AuthService,
    UserService,
    TicketsService,
    NewsService,
    LoaderService,
    DonationsService,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy }
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
