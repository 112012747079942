import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { User } from 'src/app/models/user.model';

import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/database';
import DataSnapshot = firebase.database.DataSnapshot;

@Injectable({
  providedIn: 'root'
})
export class UserService {
  
  user$ = new Subject<User>();
  user = new User("","",0);

  constructor() {
    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        setTimeout(() => {
          this.getUserById();
        }, 1000);
      } else {
        this.resetAll();
      }
    });
  }

  emitUser() {
    this.user$.next(this.user);
  }

  resetAll() {
    this.resetUser();
  }

  resetUser() {
    this.user = new User("","",0);
    this.emitUser();
  }

  getUserId() {
    return (firebase.auth().currentUser).uid;
  }

  getUserById() {
    return new Promise((resolve, reject) => {
      firebase.database().ref("Users")
      .child(this.getUserId()).once("value").then(
        (data: DataSnapshot) => {
          this.user = new User(
            data.val()._email,
            data.val()._username,
            data.val()._number);
          this.emitUser();
          resolve(data.val());
        },
        (error) => {
          reject(error);
        }
      );
    });
  }

  saveUser(email: string, username: string, number: number) {
    return new Promise((resolve, reject) => {
      let ref = firebase.database().ref('Users' + "/" + this.getUserId());
      ref.set(new User(email, username, number)).then(() => {
        ref.once('value').then((snap) => {
          this.user = new User(email, username, number);
          this.emitUser();
          resolve(snap);
        },
        (error) => {
          reject(error);
        });
      });
    });
  }

  editUsername(username: string) {
    return new Promise((resolve, reject) => {
      let ref = firebase.database().ref('Users' + "/" + this.getUserId() + 
      "/_username");
      ref.set(username).then(() => {
        ref.once('value').then((snap) => {
          this.user.username = username;
          this.emitUser();
          resolve(snap);
        },
        (error) => {
          reject(error);
        });
      });
    });
  }

  editNumber(number: number) {
    return new Promise((resolve, reject) => {
      let ref = firebase.database().ref('Users' + "/" + this.getUserId() + 
      "/_number");
      ref.set(number).then(() => {
        ref.once('value').then((snap) => {
          this.user.number = number;
          this.emitUser();
          resolve(snap);
        },
        (error) => {
          reject(error);
        });
      });
    });
  }
  
}
