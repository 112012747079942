import { Component } from '@angular/core';
import { Platform } from '@ionic/angular';
import { SplashScreen } from '@capacitor/splash-screen';
import firebase from 'firebase/compat/app';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {

  constructor(
    private platform: Platform
  ) {
    this.platform.ready().then(() => {
      let config = {
        apiKey: "AIzaSyALXGRiwV4WAqzA4rq266v2FKo09RIoE9s",
        authDomain: "love-in-game-project.firebaseapp.com",
        databaseURL: "https://love-in-game-project-default-rtdb.europe-west1.firebasedatabase.app",
        projectId: "love-in-game-project",
        storageBucket: "love-in-game-project.appspot.com",
        messagingSenderId: "829870994556",
        appId: "1:829870994556:web:819919552e79df15aa85f9",
        measurementId: "G-65QYZ82023"
      };
      firebase.initializeApp(config);
      setTimeout(() => {
        SplashScreen.hide();
      }, 5000);
    });
  }

}
