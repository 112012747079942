import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { Donations } from 'src/app/models/donations.model';
import { DatePipe } from '@angular/common';

import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/database';
import DataSnapshot = firebase.database.DataSnapshot;

@Injectable({
  providedIn: 'root'
})
export class DonationsService {
  
  donations$ = new Subject<Donations>();
  donations = new Donations(0,0);
  
  totalDonations$ = new Subject<Donations>();
  totalDonations = new Donations(0,0);

  constructor(
    private datepipe: DatePipe
  ) {
    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        setTimeout(() => {
          this.getDonationsById();
        }, 1000);
      } else {
        this.resetDonations();
      }
    });
    this.getTotalDonations();
  }

  emitDonations() {
    this.donations$.next(this.donations);
  }

  emitTotalDonations() {
    this.totalDonations$.next(this.totalDonations);
  }

  resetDonations() {
    this.donations = new Donations(0,0);
    this.emitDonations();
  }

  resetTotalDonations() {
    this.totalDonations = new Donations(0,0);
    this.emitTotalDonations();
  }

  getUserId() {
    return (firebase.auth().currentUser).uid;
  }

  getDonationsById() {
    return new Promise((resolve, reject) => {
      firebase.database().ref("Donations")
      .child(this.datepipe.transform(new Date(), 'yyyy'))
      .child(this.datepipe.transform(new Date(), 'MM'))
      .child(this.getUserId()).once("value").then(
        (data: DataSnapshot) => {
          if (data.val() == null) {
            this.saveDonations();
            this.donations = new Donations(0, 0);
          } else {
            this.donations = new Donations(
              data.val()._for_assoc, 
              data.val()._for_appli);
          }
          this.emitDonations();
          resolve(data.val());
        },
        (error) => {
          reject(error);
        }
      );
    });
  }

  saveDonations() {
    return new Promise((resolve, reject) => {
      let ref = firebase.database().ref('Donations' + "/" + this.datepipe.transform
      (new Date(), 'yyyy/MM/') + this.getUserId());
      ref.set(new Donations(0, 0)).then(() => {
        ref.once('value').then((snap) => {
          resolve(snap);
        },
        (error) => {
          reject(error);
        });
      });
    });
  }

  getTotalDonations() {
    return new Promise((resolve, reject) => {
      firebase.database().ref("Donations")
      .child(this.datepipe.transform(new Date(), 'yyyy'))
      .child(this.datepipe.transform(new Date(), 'MM'))
      .child("Total").once("value").then(
        (data: DataSnapshot) => {
          if (data.val() == null) {
            this.saveTotalDonations();
            this.totalDonations = new Donations(0, 0);
          } else {
            this.totalDonations = new Donations(
              data.val()._for_assoc, 
              data.val()._for_appli);
          }
          this.emitTotalDonations();
          resolve(data.val());
        },
        (error) => {
          reject(error);
        }
      );
    });
  }

  saveTotalDonations() {
    return new Promise((resolve, reject) => {
      let ref = firebase.database().ref('Donations' + "/" + this.datepipe.transform
      (new Date(), 'yyyy/MM/') + "Total");
      ref.set(new Donations(0, 0)).then(() => {
        ref.once('value').then((snap) => {
          resolve(snap);
        },
        (error) => {
          reject(error);
        });
      });
    });
  }

  addDonationsForAssoc(number: number) {
    let initialNumber = this.donations.for_assoc;
    let newNumber = initialNumber + number;
    return new Promise((resolve, reject) => {
      let ref = firebase.database().ref('Donations' + "/" + this.datepipe.transform
      (new Date(), 'yyyy/MM/') + this.getUserId() + "/_for_assoc");
      ref.set(newNumber).then(() => {
        ref.once('value').then((snap) => {
          this.donations.for_assoc = newNumber;
          this.emitDonations();
          resolve(snap);
        },
        (error) => {
          reject(error);
        });
      });
    });
  }

  addDonationsForAppli(number: number) {
    let initialNumber = this.donations.for_appli;
    let newNumber = initialNumber + number;
    return new Promise((resolve, reject) => {
      let ref = firebase.database().ref('Donations' + "/" + this.datepipe.transform
      (new Date(), 'yyyy/MM/') + this.getUserId() + "/_for_appli");
      ref.set(newNumber).then(() => {
        ref.once('value').then((snap) => {
          this.donations.for_appli = newNumber;
          this.emitDonations();
          resolve(snap);
        },
        (error) => {
          reject(error);
        });
      });
    });
  }

  addDonationsForTotalAssoc(number: number) {
    this.getTotalDonations();
    let initialNumber = this.totalDonations.for_assoc;
    let newNumber = initialNumber + number;
    return new Promise((resolve, reject) => {
      let ref = firebase.database().ref('Donations' + "/" + this.datepipe.transform
      (new Date(), 'yyyy/MM/') + "Total/_for_assoc");
      ref.set(newNumber).then(() => {
        ref.once('value').then((snap) => {
          this.totalDonations.for_assoc = newNumber;
          this.emitDonations();
          resolve(snap);
        },
        (error) => {
          reject(error);
        });
      });
    });
  }

  addDonationsForTotalAppli(number: number) {
    this.getTotalDonations();
    let initialNumber = this.totalDonations.for_appli;
    let newNumber = initialNumber + number;
    return new Promise((resolve, reject) => {
      let ref = firebase.database().ref('Donations' + "/" + this.datepipe.transform
      (new Date(), 'yyyy/MM/') + "Total/_for_appli");
      ref.set(newNumber).then(() => {
        ref.once('value').then((snap) => {
          this.totalDonations.for_appli = newNumber;
          this.emitDonations();
          resolve(snap);
        },
        (error) => {
          reject(error);
        });
      });
    });
  }
  
}
