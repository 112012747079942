export class News {
    private _background: string;
    private _image: string;
    private _link: string;


	constructor(background: string, image: string, link: string) {
		this._background = background;
		this._image = image;
		this._link = link;
	}

    /**
     * Getter background
     * @return {string}
     */
	public get background(): string {
		return this._background;
	}

    /**
     * Getter image
     * @return {string}
     */
	public get image(): string {
		return this._image;
	}

    /**
     * Getter link
     * @return {string}
     */
	public get link(): string {
		return this._link;
	}

}
