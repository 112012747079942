import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { Tickets } from 'src/app/models/tickets.model';
import { DatePipe } from '@angular/common';

import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/database';
import DataSnapshot = firebase.database.DataSnapshot;

@Injectable({
  providedIn: 'root'
})
export class TicketsService {
  
  tickets$ = new Subject<Tickets>();
  tickets = new Tickets(0,0,0);

  constructor(
    private datepipe: DatePipe
  ) {
    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        setTimeout(() => {
          this.getTicketsById();
        }, 1000);
      } else {
        this.resetAll();
      }
    });
  }

  emitTickets() {
    this.tickets$.next(this.tickets);
  }

  resetAll() {
    this.resetTickets();
  }

  resetTickets() {
    this.tickets = new Tickets(0,0,0);
    this.emitTickets();
  }

  getUserId() {
    return (firebase.auth().currentUser).uid;
  }

  getTicketsById() {
    return new Promise((resolve, reject) => {
      firebase.database().ref("Tickets")
      .child(this.datepipe.transform(new Date(), 'yyyy'))
      .child(this.datepipe.transform(new Date(), 'MM'))
      .child(this.getUserId()).once("value").then(
        (data: DataSnapshot) => {
          if (data.val() == null) {
            this.saveTickets();
            this.tickets = new Tickets(0, 0, 0);
          } else {
            this.tickets = new Tickets(
              data.val()._by_games, 
              data.val()._by_assoc_don, 
              data.val()._by_appli_don);
          }
          this.emitTickets();
          resolve(data.val());
        },
        (error) => {
          reject(error);
        }
      );
    });
  }

  saveTickets() {
    return new Promise((resolve, reject) => {
      let ref = firebase.database().ref('Tickets' + "/" + this.datepipe.transform
      (new Date(), 'yyyy/MM/') + this.getUserId());
      ref.set(new Tickets(0, 0, 0)).then(() => {
        ref.once('value').then((snap) => {
          resolve(snap);
        },
        (error) => {
          reject(error);
        });
      });
    });
  }

  addTicketsByGames(number: number) {
    let initialNumber = this.tickets.by_games;
    let newNumber = initialNumber + number;
    if (newNumber > 10) {
      newNumber = 10;
    }
    return new Promise((resolve, reject) => {
      let ref = firebase.database().ref('Tickets' + "/" + this.datepipe.transform
      (new Date(), 'yyyy/MM/') + this.getUserId() + "/_by_games");
      ref.set(newNumber).then(() => {
        ref.once('value').then((snap) => {
          this.tickets.by_games = newNumber;
          this.emitTickets();
          resolve(snap);
        },
        (error) => {
          reject(error);
        });
      });
    });
  }

  addTicketsByAssocDon(number: number) {
    let initialNumber = this.tickets.by_assoc_don;
    let newNumber = initialNumber + number;
    if (newNumber > 10) {
      newNumber = 10;
    }
    return new Promise((resolve, reject) => {
      let ref = firebase.database().ref('Tickets' + "/" + this.datepipe.transform
      (new Date(), 'yyyy/MM/') + this.getUserId() + "/_by_assoc_don");
      ref.set(newNumber).then(() => {
        ref.once('value').then((snap) => {
          this.tickets.by_assoc_don = newNumber;
          this.emitTickets();
          resolve(snap);
        },
        (error) => {
          reject(error);
        });
      });
    });
  }

  addTicketsByAppliDon(number: number) {
    let initialNumber = this.tickets.by_appli_don;
    let newNumber = initialNumber + number;
    if (newNumber > 10) {
      newNumber = 10;
    }
    return new Promise((resolve, reject) => {
      let ref = firebase.database().ref('Tickets' + "/" + this.datepipe.transform
      (new Date(), 'yyyy/MM/') + this.getUserId() + "/_by_appli_don");
      ref.set(newNumber).then(() => {
        ref.once('value').then((snap) => {
          this.tickets.by_appli_don = newNumber;
          this.emitTickets();
          resolve(snap);
        },
        (error) => {
          reject(error);
        });
      });
    });
  }
  
}
